import styled from "styled-components";
import Q1 from "./images/ev.jpg";
import Q2 from "./images/solar.jpg";
import { Link as LinkR } from "react-router-dom";

export const OuterContainer = styled.div`
  height: 100vh;
  background-image: ${(props) => `url(${props.bg ? Q2 : Q1})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 0.5rem;
  filter: contrast(115%);
`;

export const NavBtn = styled.nav`
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  white-space: nowrap;
  height: 2rem;
  width: 4.5rem;
  color: white;
  font-size: 16px;
  outline: none;
  border: solid white 1px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0.5em 0.5rem -0.4rem;
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
    background: none;
    color: white;
  }
`;

export const NavLinks = styled(LinkR)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;

  &.active {
    border-bottom: 2px solid white;
  }

  @media screen and (max-width: 2500px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1900px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1275px) {
    font-size: 14px;

    &.active {
      border-bottom: none;
    }
  }
`;

export const GlassContainer = styled.div`
  width: 75%;
  height: 80%;
  position: absolute;
  top: 11%;
  left: 5%;
  z-index: 2;
  border-radius: 1vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border: solid 2px transparent;
  background-clip: padding-box;

  @media screen and (max-width: 1000px) {
    width: 90%;
    height: 85%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 0.5rem;
`;

export const TitleText = styled.div`
  font-size: 1.1rem;
  color: white;
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-shadow: 1px 1px 3px black;
`;

export const BorderBottomContainer = styled.div`
  display: flex;
  padding: 0.5rem 0.75rem 0 0.75rem;
`;

export const BorderBottomLine = styled.div`
  width: 100%;
  border-bottom: ${({ selected }) =>
    selected ? "solid 2px white" : "solid 2px grey"};
`;

export const BorderBottomLine2 = styled.div`
  width: 100%;
  border-bottom: ${({ selected }) =>
    selected ? "solid 2px grey" : "solid 2px white"};
`;

export const FormContainer = styled.form`
  padding: 1rem 0.75rem 0 0.75rem;
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow-y: scroll;
`;

export const Label = styled.label`
  color: white;
  padding: 0.5rem 0 0 0;
`;

export const AnswerSection = styled.div`
  margin: 0.75rem 0 2rem 0;
  display: inline-block;
  position: relative;
  height: 28px;
  width: 150px;

  border: 1px solid;
  border-radius: 3px;
  background: #444;
  border-color: #111111 #0a0a0a black;
  background-image: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.4)
  );
  background-image: -moz-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
  background-image: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
  -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1),
    0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2);
  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 9px;
    right: 10px;
    width: 0;
    height: 0;
    border: 4px dashed;
    border-color: #888888 transparent;
    pointer-events: none;
    border-bottom-style: solid;
    border-top: none;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 9px;
    right: 10px;
    width: 0;
    height: 0;
    border: 4px dashed;
    border-color: #888888 transparent;
    pointer-events: none;
    margin-top: 7px;
    border-top-style: solid;
    border-bottom: none;
  }
`;

export const Select = styled.select`
  position: relative;
  width: 130%;
  margin: 0;
  padding: 6px 8px 6px 10px;
  height: 28px;
  line-height: 14px;
  font-size: 0.9rem;
  color: white;
  background: #f2f2f2;
  background: rgba(0, 0, 0, 0) !important;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    z-index: 3;
    width: 100%;
    color: grey;
    outline: 2px solid #49aff2;
    outline: 2px solid -webkit-focus-ring-color;
    outline-offset: -2px;
  }
`;

export const Option = styled.option`
  margin: 3px;
  padding: 6px 8px;
  text-shadow: none;
  background: #f2f2f2;
  border-radius: 3px;
  cursor: pointer;
`;

export const SecondLabel = styled.label`
  color: white;
  padding: 0 0 0 2rem;
`;

export const SecondAnswerSection = styled(AnswerSection)`
  margin: 0.75rem 0 0.5rem 2rem;
`;

export const ButtonFirstContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuoteButton = styled.button`
  width: 30%;
  margin-top: 5rem;
  background: #ccdad9;
  padding: 0.5rem 0;
  border: none;
  border-radius: 0.5vh;
  color: black;
  font-size: 1rem;
  cursor: pointer;
  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 800px) {
    width: 48%;
    margin-top: 0.75rem;
  }
`;

export const SubmitButton = styled.button`
  width: 30%;
  margin-top: 5rem;
  background: #ccdad9;
  padding: 0.5rem 0;
  border: none;
  border-radius: 0.5vh;
  color: black;
  font-size: 1rem;
  cursor: pointer;
  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 800px) {
    width: 48%;
    margin-top: 0.75rem;
  }
`;

export const UploadContainer = styled.div`
  margin: 1rem 0 0 0;
`;

export const FormInput = styled.input`
  margin: 1rem 0 0 0;
  padding: 0.5rem;
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  font-size: 1rem;
  line-height: 2.4rem;
  vertical-align: middle;
  border: none;

  width: 100%;
  line-height: 100%;
  font-family: "Poppins", sans-serif;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 1);
  }

  &:focus {
    outline: none;
  }
`;
