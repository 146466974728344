import styled, { keyframes } from "styled-components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const expand = keyframes`
  from {
    max-height: 0;
    
  }
  to {
    max-height: 500px;
    
  }
`;

const collapse = keyframes`
  from {
    max-height: 500px;
    
  }
  to {
    max-height: 0;
    
  }
`;

export const OuterFAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 101vh;
  width: 100%;
  position: relative;
  z-index: 1;
  background: rgb(7, 9, 17);
`;

export const HeaderContainer = styled.div`
  color: white;
  width: 10%;
  height: 10%;
  font-size: 2.5rem;
  margin: 5rem 0 0 5rem;

  @media screen and (max-width: 1800px) {
    margin: 5rem 0 0 1.5rem;
  }
`;

export const QuestionContainer = styled.div`
  color: white;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 5rem 0;
`;

export const FAQContainer = styled.div`
  width: 100%;
  max-width: 1600px;
`;

export const Question = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.5rem 1rem 1.5rem 0.5rem;
  border-bottom: 1px solid #ccc;
  background: rgb(7, 9, 17);
`;

export const QuestionText = styled.div`
  flex: 1;
  font-size: 20px;
  padding: 0 0.75rem 0 0;
`;

export const Answer = styled.div`
  position: relative;
  z-index: 1;
  font-size: 18px;
  margin: 2rem 0.8rem 0 0.8rem;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  color: rgb(208, 204, 195);
  animation: ${({ isVisible }) => (isVisible ? expand : collapse)} 1s
    ease-in-out;
`;

export const ChevronDownIcon = styled(FaChevronDown)`
  font-size: 2.25rem;
`;

export const ChevronUpIcon = styled(FaChevronUp)`
  font-size: 2.25rem;
`;
