import styled, { keyframes } from "styled-components";

const riseUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const HeroContainer = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 667px;
  background: rgb(10, 11, 11);
  position: relative;
  z-index: 1;
`;

export const Mask = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 1080px;
  background-color: rgba(10, 10, 10, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  cursor: pointer;
`;

export const PopupContainer = styled.div`
  height: 75%;
  width: 80%;
  border: solid 4px white;
  border-radius: 1vh;
  position: relative;
  top: 0%;
  left: 0%;
  transform: translate(12.5%, 20%);
  z-index: 99;
  background-color: rgba(55, 55, 55, 0.5);

  @media screen and (max-width: 1450px) {
    height: 50%;
    width: 80%;

    transform: translate(12.5%, 27.5%);
  }
`;

export const PopupText = styled.div`
  position: relative;
  top: -95%;
  left: 5%;
  z-index: 100;

  font-size: 3rem;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1450px) {
    top: -95%;
    left: 5%;

    font-size: 2rem;
  }

  @media screen and (max-width: 1070px) {
    top: -95%;
    left: 0;
    text-align: center;

    font-size: 1.25rem;
  }
`;

export const PopupImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  filter: saturate(30%);
  object-fit: cover;
  border-radius: 1vh;
  z-index: 1;
`;

export const InnerSection = styled.div`
  max-width: 2560px;
  margin: 0 auto 0 auto;
  height: 100%;
`;

export const ImgContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  max-width: 2560px;
  margin: 0 auto 0 auto;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  background-image: url("/images/solar.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  filter: contrast(150%);

  @media screen and (max-width: 800px) {
    background-image: url("/images/vert.jpg");
    filter: contrast(100%);
  }
`;

// export const Img = styled.img`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   filter: contrast(150%);
//   object-fit: cover;
//   z-index: 1;
// `;

export const ObjectImg = styled.img`
  position: relative;
  width: 4rem;
  height: 4rem;
  filter: contrast(150%);
  object-fit: contain;
  z-index: 1;
  margin: -0.35rem 0 0 0;
`;

export const ObjectImg2 = styled.img`
  position: relative;
  width: 5rem;
  height: 5rem;
  filter: contrast(150%);
  object-fit: contain;
  z-index: 1;
  margin: -0.85rem 0 -0.45rem 0;
`;

export const ObjectImg3 = styled.img`
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  filter: contrast(150%);
  object-fit: contain;
  z-index: 1;
  margin: -0.35rem 0 0 0;
`;

export const ObjectImg4 = styled.img`
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  filter: contrast(150%);
  object-fit: contain;
  z-index: 1;
  margin: -0.35rem 0 0 0;
`;

export const TitleContainer = styled.div`
  width: 62.5%;
  height: 35%;
  position: absolute;
  top: 20%;
  left: 15%;
  z-index: 2;
  border-radius: 1vh;
  background: rgba(0, 0, 0, 0.5);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(8px);
  border: solid 1px grey;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${riseUp} 1s ease-in-out forwards;

  /* @media screen and (max-width: 1550px) {
    height: 30%;
    top: 27.5%;
    left: 12%;
  } */

  @media screen and (max-width: 1260px) {
    width: 67.5%;
  }

  @media screen and (max-width: 900px) {
    height: 25%;
    width: 80%;
    left: 10%;
    top: 25%;
  }

  @media screen and (max-width: 800px) {
    height: 20%;
    width: 90%;
    left: 5%;
  }

  @media screen and (max-width: 450px) {
    top: 12%;
    height: 22.5%;
    width: 96%;
    left: 2%;
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    border: none;
  }
`;

export const HeroH1 = styled.div`
  font-size: 10rem;
  font-weight: normal;
  color: white;
  padding-left: 2rem;
  position: relative;
  z-index: 1;
  display: flex;
  line-height: 80%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 3000px) {
    font-size: 8rem;
  }

  @media screen and (max-width: 2450px) {
    font-size: 6.5rem;
  }

  @media screen and (max-width: 1980px) {
    font-size: 5.75rem;
  }

  @media screen and (max-width: 1750px) {
    font-size: 5rem;
  }

  @media screen and (max-width: 1325px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 990px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 840px) {
    font-size: 3.25rem;
  }

  @media screen and (max-width: 700px) {
    padding: 0 1.5rem 0 1.5rem;
    font-size: 3rem;
  }

  @media screen and (max-width: 640px) {
    padding: 0 1rem 0 1rem;
    font-size: 2.9rem;
  }

  @media screen and (max-width: 525px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 430px) {
    font-size: 2.15rem;
    padding: 0 0 0 0.5rem;
  }
`;

export const WordOne = styled.div``;

export const HeroH2 = styled.h1`
  font-size: 8rem;
  font-weight: normal;
  color: white;
  padding-left: 2rem;
  position: relative;
  z-index: 1;
  margin: 0.5rem 0 0 0;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 3000px) {
    font-size: 6rem;
  }

  @media screen and (max-width: 2450px) {
    font-size: 5.5rem;
  }

  @media screen and (max-width: 1980px) {
    font-size: 4.75rem;
  }

  @media screen and (max-width: 1750px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 1325px) {
    font-size: 3.35rem;
  }

  @media screen and (max-width: 1025px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 990px) {
    font-size: 2.75rem;
  }

  @media screen and (max-width: 750px) {
    margin: 0.5rem 0.5rem 0 0;
  }

  @media screen and (max-width: 700px) {
    margin: 0;
    padding: 1rem 1.5rem 0 1.5rem;
    font-size: 2.5rem;
  }

  @media screen and (max-width: 640px) {
    padding: 1rem 1rem 0 1rem;
    font-size: 2.15rem;
  }

  @media screen and (max-width: 525px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 430px) {
    padding: 0.25rem 1rem 0 0.5rem;
    font-size: 1.85rem;
  }
`;

export const WordTwo = styled.div`
  padding: 0 0 0 0.65rem;

  /* @media screen and (max-width: 525px) {
    padding: 0.75rem 0 0 40%;
  }

  @media screen and (max-width: 430px) {
    padding: 0.75rem 0 0 35%;
  } */
`;

export const CircleContainer = styled.div`
  height: 17rem;
  width: 17rem;
  border: solid 6px white;
  position: absolute;
  top: 47%;
  left: 70%;
  z-index: 2;
  border-radius: 50%;
  padding: 5.75rem 0 0 2rem;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  cursor: pointer;

  @media screen and (max-width: 1550px) {
    height: 15rem;
    width: 15rem;
    top: 35%;
    left: 65%;
  }

  @media screen and (max-width: 890px) {
    height: 13rem;
    width: 13rem;
    top: 35%;
    left: 65%;
  }

  @media screen and (max-width: 750px) {
    height: 11rem;
    width: 11rem;
  }

  @media screen and (max-width: 535px) {
    height: 10rem;
    width: 10rem;
    top: 33%;
    left: 57.5%;
  }

  @media screen and (max-width: 450px) {
    top: 31.5%;
    left: 51%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CircleText = styled.div`
  color: white;
  font-size 2.5rem;
  cursor: pointer;
  line-height: 100%;
  position: relative;

  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1750px) {
    font-size: 2.25rem;
  }

  @media screen and (max-width: 1550px) {
    margin-top: -1.25rem;
    line-height: 150%;
  }

  @media screen and (max-width: 890px) {
    font-size 2rem;
    margin-top: -2.75rem;
    line-height: 235%;
  }

  @media screen and (max-width: 750px) {
    font-size 1.75rem;
    line-height: 250%;
    top: -15%;
    left: -10%;
  }

  @media screen and (max-width: 535px) {
    font-size 1.5rem;
    line-height: 275%;
    top: -25%;
  }
`;

export const Icon = styled.div`
  font-size: 4rem;
  color: white;
  padding: 0 1rem 0 0;
  cursor: pointer;
  text-align: right;
`;

export const IconButton = styled.div``;

export const ContentContainer = styled.div`
  height: 25%;
  width: 62.5%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 62.5%;
  left: 15%;
  z-index: 3;
  background-clip: padding-box;
  border-radius: 0.5vh;
  padding: 0 0.25rem 0 0;
  animation: ${riseUp} 1.5s ease-in-out forwards;

  @media screen and (max-width: 900px) {
    top: 55%;
    left: 10%;
    height: 25%;
    width: 75%;
  }

  @media screen and (max-width: 800px) {
    top: 52.5%;
    left: 5%;
    width: 80%;
    height: 27.5%;
  }

  @media screen and (max-width: 640px) {
    width: 85%;
  }

  @media screen and (max-width: 450px) {
    width: 98%;
    left: 1%;
    top: 30%;
  }
`;

export const HeroContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0.25rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* @media screen and (max-width: 1215px) {
    top: 7.5%;
  } */
`;

export const HeroContainerObject = styled.div`
  width: 90%;
  height: 50%;

  @media screen and (max-width: 1260px) {
    width: 95%;
    height: 45%;
  }

  @media screen and (max-width: 1050px) {
    width: 98%;
    height: 40%;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const HeroObject = styled.div`
  color: white;
  font-size: 1.65rem;
  font-family: "Poppins", sans-serif;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
  height: 100%;
  padding: 1rem 0 0 2rem;
  border-radius: 0.35rem;
  border: solid 1px grey;
  min-height: 6rem;

  @media screen and (max-width: 1750px) {
    font-size: 1.45rem;
  }

  @media screen and (max-width: 1460px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 1260px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 1190px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 1050px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 700px) {
    padding: 1rem 0 0 1.5rem;
  }

  @media screen and (max-width: 640px) {
    padding: 1rem 0 0 1rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1rem;
    padding: 0.75rem 1rem 0 0.75rem;
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    border: none;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75);
  }
`;

export const CallNumber = styled.div`
  visibility: hidden;
  border: solid 1px red;

  /* @media screen and (max-width: 1215px) {
    visibility: visible;
    font-size: 1.45rem;
    color: #fff;
    text-align: right;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 35%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    padding: 0.25rem 0.5rem 0 0.5rem;
    border-radius: 0.35rem;
    border: solid 1px grey;
    height: 2.5rem;
  } */
`;

export const HeroP = styled.p`
  color: white;
  font-size: 25px;
  font-weight: 100;
  text-align: left;
  margin-right: 2rem;

  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 2800px) {
    font-size: 1.25rem;
    margin-right: 1rem;
  }

  @media screen and (max-width: 2100px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 1750px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 0.8rem;
  }
`;

export const HeroT = styled.p`
  color: white;
  font-size: 25px;
  font-weight: 100;
  text-align: left;
  margin-left: 2rem;
  margin-right: 0.5rem;

  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 2800px) {
    font-size: 1.25rem;
    margin-left: 1rem;
  }

  @media screen and (max-width: 2100px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 1750px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 1215px) {
    margin: 1.75rem 0 0 0;
  }

  @media screen and (max-width: 750px) {
    margin: 1.25rem 0 0 0;
  }

  @media screen and (max-width: 650px) {
    margin: 1.75rem 0 0 0;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 535px) {
    margin: 1.15rem 0 0 0;
  }
`;

export const Divide = styled.div`
  height: 70%;
  border-left: solid white;

  @media screen and (max-width: 1550px) {
    border-left: solid 2px white;
  }

  @media screen and (max-width: 1215px) {
    display: none;
  }
`;

export const BackgroundBubble = styled.p`
  height: 30%;
  width: 20vw;
  background-color: #76b820;
  border-radius: 0.5vh;
  position: relative;
  z-index: 0;
  top: -20%;
  left: 20%;

  @media screen and (max-width: 1420px) {
    height: 35%;
  }

  @media screen and (max-width: 1100px) {
    left: 27.5%;
    height: 30%;
    width: 25vw;
  }

  @media screen and (max-width: 600px) {
    left: 18%;
    height: 35%;
    width: 30vw;
  }
`;
