import React from "react";
import {
  OuterContainer,
  Container,
  Img,
  Img2,
  InfoContainer,
  Text,
  Text2,
  BottomInfoContainer,
  OneContainer,
  TitleText,
  InnerText,
  PurchaseContainer,
  BuyText,
  NavBtn,
  NavLinks,
  ButtonContainer,
} from "./style";
import EV3 from "./images/EV2.png";
import EV2 from "./images/EV.png";

const EVSection = () => {
  return (
    <OuterContainer>
      <InfoContainer>
        <Text>Professional Installation of Electric Vehicle Chargers</Text>
        <Text2>See Details Below</Text2>
      </InfoContainer>
      <Container>
        <Img2 src={EV2} alt="Not here" />
        <Img src={EV3} alt="Not here" />
      </Container>
      <BottomInfoContainer>
        <OneContainer>
          <TitleText>Electric Vehicle Charger</TitleText>
          <InnerText>
            We can install any charger from any car manufacturer. Cost listed is
            under the condition that the charger is installed within 6 feet of
            the main electrical panel.{" "}
          </InnerText>
          <PurchaseContainer>
            <BuyText>Purchase Price : </BuyText>
            <BuyText>$600</BuyText>
          </PurchaseContainer>
        </OneContainer>
        <ButtonContainer>
          <NavBtn>
            <NavLinks
              to="/"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-40}
            >
              Contact Us
            </NavLinks>
          </NavBtn>
          <NavBtn>
            <NavLinks
              to="/quote"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-40}
            >
              Get a Quote
            </NavLinks>
          </NavBtn>
        </ButtonContainer>
      </BottomInfoContainer>
    </OuterContainer>
  );
};

export default EVSection;
