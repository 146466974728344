import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import Ailerons from "../Ailerons-Regular.otf";

export const Nav = styled.nav`
  background: ${({ scrollNav }) =>
    scrollNav ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.4)"};
  backdrop-filter: blur(15px);
  height: 80px;
  width: 78%;
  margin: 0 auto -80px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom-left-radius: 0.5vh;
  border-bottom-right-radius: 0.5vh;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }

  @media screen and (max-width: 800px) {
    height: 80px;
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
    width: 94.5%;
    flex-direction: column;
  }
`;

export const PhoneContainer = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100vw;
    margin: 0 0 -0.5rem 0;
    color: white;
    font-weight: 500;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.15) 100%
    );
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 18px;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const Img = styled.img`
  width: 70px;
  height: 70px;
  margin: 0 0 0 0;
  cursor: pointer;
  filter: brightness(200%);
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: space-between;
    transform: translate(0%, 27.5%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    width: 100%;
  }
`;

export const BarsContainer = styled.div`
  display: none;

  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    border: solid 1px #ffffff;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const NavMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const NavItemContainer = styled.div`
  display: flex;
  height: 90%;
  margin-top: -1rem;
  background: rgba(118, 184, 32, 0.7);
  backdrop-filter: blur(15px);
  border-bottom-left-radius: 0.5vh;
  border-bottom-right-radius: 0.5vh;
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkS)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;

  font-family: "Poppins", sans-serif;

  &.active {
    border-bottom: 2px solid white;
  }

  @media screen and (max-width: 2500px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1900px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1275px) {
    font-size: 15px;
    padding: 0 0.65rem;

    &.active {
      border-bottom: none;
    }
  }
`;

export const NavBtn = styled.nav`
  border-radius: 0.5vh;
  background: rgb(10, 11, 11);
  white-space: nowrap;
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  outline: none;
  border: solid white 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0.5em 0.5rem -0.4rem;
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
    background: none;
    color: white;
  }

  @media screen and (max-width: 2500px) {
    font-size: 14px;
  }

  @media screen and (max-width: 800px) {
    height: 3rem;
    margin-top: 0;
    border-radius: 2rem;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 0.5vh;
  background: rgb(10, 11, 11);
  white-space: nowrap;
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  outline: none;
  border: solid white 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0.5em 0.5rem -0.4rem;
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
    background: none;
    color: white;
  }

  @media screen and (max-width: 2500px) {
    font-size: 14px;
  }
`;

export const NavTitle = styled.div`
  color: white;
  font-family: "Ailerons";
  font-size: 28px;

  @media screen and (max-width: 2500px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1900px) {
    font-size: 20px;
  }

  @font-face {
    font-family: "Ailerons";
    src: url(${Ailerons}) format("truetype");
  }
`;
