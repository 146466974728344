import styled from "styled-components";

export const OuterAboutContainer = styled.div`
  background: rgb(10, 11, 11);
  display: flex;
  justify-content: center;
  height: 100vh;
  min-height: 1080px;
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const AboutContainer = styled.div`
  background: white;
  display: flex;
  max-width: 2560px;
  margin: 0 auto 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AboutContentOne = styled.div`
  position: relative;
  top: 12.5%;
  left: 7.5%;
  width: 27.5%;
  height: 50%;

  @media screen and (max-width: 1280px) {
    top: 5%;
    left: 0%;
    width: 60%;
    height: 40%;
  }

  @media screen and (max-width: 650px) {
    width: 90%;
  }
`;

export const Img = styled.img`
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 0.5vh;
  border-top-right-radius: 0.5vh;
  box-shadow: 3px 12px 20px #888888;
`;

export const LowerBox = styled.div`
  width: 100%;
  height: 25%;
  border-left: solid 1px lightgrey;
  border-bottom-left-radius: 0.5vh;
  border-bottom-right-radius: 0.5vh;
  background: rgba(238, 238, 238, 1);
  margin-top: -4px;

  @media screen and (max-width: 650px) {
    height: 10%;
  }
`;

export const ContentTextContainer = styled.div`
  position: relative;
  top: -120%;
  left: 10%;
  z-index: 3;

  @media screen and (max-width: 650px) {
    top: -105%;
    left: 5%;
  }
`;

export const TextInnerContainer = styled.div`
  display: flex;
`;

export const ContentMidText = styled.div`
  color: white;
  font-size: 5rem;
  font-family: "Poppins", sans-serif;
  line-height: 100%;

  @media screen and (max-width: 2150px) {
    font-size: 3.75rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 2rem;
  }
`;

export const ContentDescription = styled.div`
  color: white;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  margin-left: 1rem;

  @media screen and (max-width: 2150px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 1.25rem;
    margin-left: 0.5rem;
  }
`;

export const SmallDescription = styled.div`
  color: white;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  margin: 1rem 0 0 1rem;

  @media screen and (max-width: 2150px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 1.05rem;
    margin: 0.5rem 0 0 0.5rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 1.15rem;
    margin: 0.5rem 0 0 0;
  }
`;

export const AboutContentTwo = styled.div`
  position: relative;
  top: 12.5%;
  left: 7.5%;
  height: 62.5%;
  width: 65%;

  @media screen and (max-width: 1280px) {
    top: 5%;
    left: 0%;
    width: 95%;
    height: 62.5%;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  position: relative;
  top: 12.5%;
  left: 7.5%;
  height: 60%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 650px) {
    top: 0%;
    width: 95%;
    left: 2%;
  }
`;

export const MidText = styled.div`
  color: #333333;
  font-size: 4.75rem;
  font-family: "Poppins", sans-serif;
  line-height: 100%;
  margin: 0 1.5rem 0 1.5rem;

  @media screen and (max-width: 2400px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 2150px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 1760px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 2.25rem;
  }

  @media screen and (max-width: 880px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 795px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 1rem;
  }
`;

export const Description = styled.div`
  color: #333333;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  margin: auto 0 auto 0;

  @media screen and (max-width: 2150px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 1760px) {
    font-size: 1.35rem;
  }

  @media screen and (max-width: 795px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 0.75rem;
    margin: auto 0 auto -1rem;
  }
`;

export const BigText = styled.div`
  color: #333333;
  font-size: 9rem;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 2400px) {
    font-size: 8rem;
  }

  @media screen and (max-width: 2150px) {
    font-size: 6.75rem;
  }

  @media screen and (max-width: 1760px) {
    font-size: 6rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 5rem;
  }

  @media screen and (max-width: 880px) {
    font-size: 4.5rem;
  }

  @media screen and (max-width: 795px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 2.15rem;
  }
`;

export const LowerContainer = styled.div`
  position: relative;
  top: 20%;
  height: 20%;
  width: 100%;
  background: radial-gradient(400px at 60% bottom, #545c50 0%, #202f28 100%);

  @media screen and (max-width: 1280px) {
    top: 10%;
  }

  @media screen and (max-width: 650px) {
    top: 5%;
  }
`;

export const BoldText = styled.a`
  position: relative;
  top: 15%;
  left: 5%;
  color: white;
  font-size: 4rem;
  text-decoration: underline;
  cursor: pointer;

  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 2400px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 2150px) {
    top: 25%;
    font-size: 2.75rem;
  }

  @media screen and (max-width: 1760px) {
    font-size: 2rem;
    top: 35%;
  }

  @media screen and (max-width: 880px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 795px) {
    top: 40%;
    font-size: 1.45rem;
  }

  @media screen and (max-width: 650px) {
    font-size: 0.95rem;
    left: 2.5%;
    width: 80%;
  }
`;

export const ImgTwo = styled.img`
  width: 100%;
  height: 70%;
  border-radius: 0.5vh;

  @media screen and (max-width: 1100px) {
    height: 60%;
  }
`;

export const AboutContentThree = styled.div`
  width: 30%;
  height: 100%;
  padding: 1rem;

  @media screen and (max-width: 1100px) {
    margin-left: -40%;
    width: 40%;
  }

  @media screen and (max-width: 750px) {
    margin-left: -5%;
    width: 100%;
  }
`;

export const AboutTitle = styled.div`
  text-align: right;
  font-size: 2rem;
  width: 100%;
  color: white;

  @media screen and (max-width: 1920px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 1500px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 1rem;
  }
`;

export const AboutDesc = styled.div`
  padding-top: 40%;
  padding-bottom: 20%;
  font-size: 20px;
  color: lightgrey;
  border-bottom: solid grey;

  @media screen and (max-width: 1920px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 1500px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 1100px) {
    padding-top: 10%;
    font-size: 0.7rem;
  }
`;
