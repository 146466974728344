import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
// import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemContainer,
  NavLinks,
  NavBtn,
  Img,
  BarsContainer,
  PhoneContainer,
} from "./style";
import Logo from "./images/index.ico";
import { FaPhoneAlt } from "react-icons/fa";

const Navbar = ({ toggle }) => {
  const [scrollNav, setscrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setscrollNav(true);
    } else {
      setscrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const handleButtonClick = () => {
    window.location.href = "tel:+1-813-467-6859";
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <PhoneContainer onClick={handleButtonClick}>
          <FaPhoneAlt />{" "}
          <span style={{ margin: " 0 0 0 0.5rem", fontSize: "0.85rem" }}>
            (813)<span style={{ margin: " 0 0 0 0.2rem" }}>467-6859</span>
          </span>
        </PhoneContainer>
        <NavbarContainer>
          <MobileIcon>
            <BarsContainer onClick={toggle}>
              <FaBars style={{ fontSize: "22px" }} />
            </BarsContainer>

            <NavBtn>
              <NavLinks
                to="NewContact"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-40}
              >
                Get a Quote
              </NavLinks>
            </NavBtn>
          </MobileIcon>
          <NavMenu>
            {/*<NavTitle>Advanced</NavTitle>*/}
            <Img to="/" src={Logo} alt="Not here" />
            <NavItemContainer>
              <NavItem>
                <NavLinks
                  to="/"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-80}
                >
                  FAQ's
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Services
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  to="projects"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Our Projects
                </NavLinks>
              </NavItem>
            </NavItemContainer>
            <NavBtn>
              <NavLinks
                to="NewContact"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-40}
              >
                Get a Quote
              </NavLinks>
            </NavBtn>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
