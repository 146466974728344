import styled, { keyframes } from "styled-components";
import { Link as LinkR } from "react-router-dom";

const gradient = keyframes`
0% {
  background-position: 0 0;
}
100% {
  background-position: 100% 0;
}
`;

export const OuterContainer = styled.div`
  background: rgb(10, 11, 11);
  display: flex;
  justify-content: center;
  height: 75vh;
  width: 100%;
  min-height: 667px;
  position: relative;
  z-index: 1;
`;

export const ContactContainer = styled.div`
  background: #202f28;
  display: flex;
  max-width: 2560px;
  margin: 0 auto 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  justify-content: space-around;
`;

export const LeftContainer = styled.div`
  position: relative;
  top: 0%;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    width: 94%;
  }
`;

export const Title = styled.div`
  font-size: 6rem;
  color: white;
  line-height: 100%;
  font-family: "Poppins", sans-serif;
  height: 25%;

  @media screen and (max-width: 2200px) {
    font-size: 5rem;
  }

  @media screen and (max-width: 1840px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 1080px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 730px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const FooterContainer = styled.div`
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  position: relative;
  z-index: 1;
  margin-top: 5rem;
  border-radius: 0.5vh;

  @media screen and (max-width: 600px) {
    height: 65%;
  }
`;

export const ContactTitle = styled.div`
  font-size: 4rem;
  color: white;
  padding: 2.75rem 0 0 2.75rem;
  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 2200px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 1000px) {
    font-size: 2.75rem;
  }

  @media screen and (max-width: 600px) {
    padding: 1.5rem 0 0 1.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
    padding: 1.25rem 0 0 1.25rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 50%;
  padding: 2rem 0 0 4rem;

  @media screen and (max-width: 600px) {
    padding: 1.5rem 0 0 2.5rem;
  }

  @media screen and (max-width: 500px) {
    padding: 1.25rem 0 0 1.5rem;
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
`;

export const NavBtn = styled.nav`
  margin-right: 1rem;
  float: right;
  border-radius: 0.5vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  white-space: nowrap;
  width: 16.5rem;
  color: white;
  font-size: 16px;
  outline: none;
  border: solid white 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0.5em 0.5rem -0.4rem;
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
    background: none;
    color: white;
  }

  @media screen and (max-width: 700px) {
    width: 13.75rem;
  }

  @media screen and (max-width: 500px) {
    margin: 3rem 1rem 0 0;
  }
`;

export const OutroContainer = styled.div`
  height: 5rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 1rem 0;
`;

export const OutLine = styled.div`
  border-top: solid 1px grey;
  width: 90%;
`;

export const OutTopContain = styled.div`
  margin: 0.5rem 0 0 0;

  @media screen and (max-width: 500px) {
    font-size: 0.85rem;
  }
`;

export const OutBottomContain = styled.a`
  margin: 0.5rem 0 0 0;
  cursor: pointer;
  color: white;

  @media screen and (max-width: 500px) {
    font-size: 0.85rem;
  }
`;

export const NavLinks = styled(LinkR)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 15px;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;

  &.active {
    border-bottom: 2px solid white;
  }

  @media screen and (max-width: 1900px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1275px) {
    &.active {
      border-bottom: none;
    }
  }

  @media screen and (max-width: 700px) {
    font-size: 13px;
  }
`;

export const Address = styled.div`
  color: white;
  font-size: 2.25rem;
  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1000px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
`;
export const Number = styled.div`
  color: white;
  font-size: 2.25rem;
  margin-top: 1.5rem;
  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1000px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
`;

export const Email = styled.div`
  color: white;
  font-size: 2.25rem;
  margin-top: 1.5rem;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
`;

export const Links = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: -0.85rem;
  width: 40%;

  @media screen and (max-width: 500px) {
    margin-top: 1.5rem;
  }
`;

export const Icon = styled.a`
  font-size: 2.75rem;
  cursor: pointer;
  color: white;
  padding-left: 0.5rem;

  @media screen and (max-width: 1000px) {
    font-size: 2.25rem;
  }
`;

export const RightContainer = styled.div`
  position: relative;
  top: 16.5%;
  width: 30%;
  display: flex;
  flex-direction: column;
  height: 77.5%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.5vh;

  @media screen and (max-width: 1470px) {
    width: 40%;
  }

  @media screen and (max-width: 1080px) {
    top: -5%;
    left: 10%;
    height: 52.5%;
    width: 80%;
  }

  @media screen and (max-width: 500px) {
    top: -5%;
    left: 5%;
    height: 52.5%;
    width: 90%;
  }
`;

export const Form = styled.form`
  background: transparent;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 25px 32px;
  border-radius: 4px;

  @media screen and (max-width: 1080px) {
    padding: 0px 32px;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom: 40px;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
`;

export const FormH2 = styled.h2`
  margin-bottom: 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 1rem;
  color: black;
  line-height: 100%;
  font-family: "Poppins", sans-serif;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 3rem;
  border-radius: 0.5vh;
  padding: 1.4rem 2rem 1.6rem;
  background: rgba(255, 255, 255, 0.1);

  &:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    height: 2px;
    border-bottom-left-radius: 0.5vh;
    border-bottom-right-radius: 0.5vh;
    background-position: 0% 0%;
    background: linear-gradient(
      to right,
      #b294ff,
      #57e6e6,
      #feffb8,
      #57e6e6,
      #b294ff,
      #57e6e6
    );
    background-size: 500% auto;
    -webkit-animation: gradient 3s linear infinite;
    animation: ${gradient} 3s linear infinite;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 1.25rem;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const FormInput = styled.input`
  flex-grow: 1;
  background-color: transparent;
  font-size: 1.8rem;
  line-height: 2.4rem;
  vertical-align: middle;
  border: none;

  width: 100%;
  line-height: 100%;
  font-family: "Poppins", sans-serif;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 1);
  }

  &:focus {
    outline: none;
  }
`;

export const FormTextArea = styled.textarea`
  padding: 16px 16px;
  margin-top: 3rem;
  margin-bottom: 32px;
  border: solid 1px black;
  border-radius: 0.5vh;
  font-size: 1.8rem;

  background-color: rgba(255, 255, 255, 0.1);
  border: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 1);
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 1.25rem;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const FormButton = styled.button`
  background: #181a1b;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.8rem;
  cursor: pointer;
  line-height: 100%;
  font-family: "Poppins", sans-serif;
`;

export const Text = styled.span`
  text-align: center;
  margin-top: 24px;
  color: #fff;
  font-size: 14px;
`;

export const ReCaptcha = styled.div`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
`;

export const Banner = styled.img`
  position: relative;
  top: 5%;
  height: 60%;
  width: 100%;
  object-fit: cover;
`;

export const Topic = styled.div`
  text-align: right;
  padding: 3% 3% 0 0;
  font-size: 3rem;
  color: white;

  @media screen and (max-width: 2200px) {
    font-size: 2.5rem;
  }
`;

////////////////////////////////////////////////////
