import React from "react";
import {
  ServicesContainer,
  Title,
  Img,
  Footer,
  TitleContainer,
  ImageContainer,
  FooterContainer,
  Text,
  ContainerOne,
  ContainerTwo,
  ContainerThree,
  OuterContainer,
  ButtonContainer,
  ButtonText,
  CardContainer,
  UpperContainer,
  MiddleContainer,
  LowerContainer,
  SubText,
  LearnMore,
  NavBtnLink,
} from "./style";
import Home from "./images/Home.jpg";

const ServicesSection = () => {
  return (
    <OuterContainer>
      <ServicesContainer id="services">
        <TitleContainer>
          <Title>Our</Title>
          <Title>Services</Title>
          <ButtonContainer>
            <ButtonText
              to="Contact"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-40}
            >
              Inquire
            </ButtonText>
          </ButtonContainer>
        </TitleContainer>
        <CardContainer>
          <UpperContainer>
            <div>
              <Text>Residential Solar Installation</Text>
              <SubText>
                Harness solar power to cut energy costs and lower your utility
                bill. Benefit from savings, sustainable energy, and reduced
                reliance on the grid.
              </SubText>
            </div>

            <NavBtnLink to="/quote">
              <LearnMore>Learn More</LearnMore>
            </NavBtnLink>
          </UpperContainer>
          <MiddleContainer>
            <Text>Detach / Re-attach Solar Systems</Text>
            <SubText>
              Need roof work? We safely remove your solar panels and reinstall
              them after repairs. Ensure your solar investment is protected and
              enjoy uninterrupted energy.
            </SubText>
          </MiddleContainer>
          <LowerContainer>
            <div>
              <Text>Electric Vehicle Charger Installation</Text>
              <SubText>
                Set up your charger in home or on site to power your electric
                vehicles
              </SubText>
            </div>

            <NavBtnLink to="/quote">
              <LearnMore>Learn More</LearnMore>
            </NavBtnLink>
          </LowerContainer>
        </CardContainer>
        <Img src={Home} alt="Not here" />
      </ServicesContainer>
    </OuterContainer>
  );
};

export default ServicesSection;
