import React, { useState } from "react";
import {
  ProjectContainer,
  TitleContainer,
  Title,
  Container,
  DataContainer,
  DataAltContainer,
  Header,
  Text,
  HeaderAlt,
  TextAlt,
  Img,
  CarouselImg,
  Subtext,
  OuterContainer,
  FirstContainer,
  SecondContainer,
  BodySection,
  InnerSection,
  Number,
  Card,
  CardTitleContainer,
  CardTitle,
  CardInnerContainer,
  InfoContainer,
  InfoTitle,
  InfoTextContainer,
  InfoDescription,
  BottomInfoContainer,
  InfoImage,
  LinkButton,
  ButtonText,
  NavigateContainer,
  GalleryContainer,
  RightArrowContainer,
  LeftArrowContainer,
  FactsContainer,
  FactsText,
} from "./style";
import Pic from "./images/Pic.jpg";
import Gal1 from "./images/Gallery1.webp";
import Gal2 from "./images/Gallery2.webp";
import Gal3 from "./images/Gallery3.webp";
import Gal4 from "./images/Gallery4.webp";
import Gal5 from "./images/Gallery5.webp";
import roof1 from "./images/roof1.webp";
import roof2 from "./images/roof2.webp";
import roof3 from "./images/roof3.webp";
import roof4 from "./images/roof4.webp";
import roof5 from "./images/roof5.webp";
import ev1 from "./images/ev1.webp";
import ev2 from "./images/ev2.webp";
import ev3 from "./images/ev3.webp";
import ev4 from "./images/ev4.webp";
import ev5 from "./images/ev5.webp";

import styled from "styled-components";
import { ReactComponent as LeftArrow } from "./images/leftArrow.svg";

const ProjectSection = () => {
  const SvgArrow = styled(LeftArrow)`
    width: 3rem;
    height: 3rem;
    fill: white;

    @media screen and (max-width: 800px) {
      width: 2rem;
      height: 2rem;
    }
  `;

  const [selected, setSelected] = useState(1);

  const [index, setIndex] = useState(0);
  const images = [Gal1, Gal2, Gal3, Gal4, Gal5];
  const roof = [roof1, roof2, roof3, roof4, roof5];
  const ev = [ev1, ev2, ev3, ev4, ev5];

  const previous = () => {
    setIndex((index + images.length - 1) % images.length);
  };

  const next = () => {
    setIndex((index + 1) % images.length);
  };

  return (
    <OuterContainer>
      <ProjectContainer id="projects">
        <Container>
          <TitleContainer>
            <Number>01.</Number>
            <Title>Clearwater</Title>
          </TitleContainer>
          <TitleContainer>
            <Number>02.</Number>
            <Title>St. Petersburg</Title>
          </TitleContainer>
          <TitleContainer>
            <Number>03.</Number>
            <Title>Tampa</Title>
          </TitleContainer>
          <TitleContainer>
            <Number>04.</Number>
            <Title>Orlando</Title>
          </TitleContainer>
        </Container>
        <Card>
          <CardTitleContainer>
            <CardInnerContainer>
              <CardTitle
                noBorder={false}
                active={selected == 1}
                onClick={() => setSelected(1)}
              >
                Residential
              </CardTitle>
              <CardTitle
                noBorder={false}
                active={selected == 2}
                onClick={() => setSelected(2)}
              >
                Roof Replacement
              </CardTitle>
              <CardTitle
                noBorder={true}
                active={selected == 3}
                onClick={() => setSelected(3)}
              >
                EV Chargers
              </CardTitle>
            </CardInnerContainer>
          </CardTitleContainer>

          {selected == 1 ? (
            <GalleryContainer>
              <CarouselImg src={images[index]} alt="Energy Reduced" />
              <NavigateContainer>
                <RightArrowContainer onClick={next}>
                  <SvgArrow />
                </RightArrowContainer>
                <LeftArrowContainer onClick={previous}>
                  <SvgArrow />
                </LeftArrowContainer>
              </NavigateContainer>
            </GalleryContainer>
          ) : selected == 2 ? (
            <GalleryContainer>
              <CarouselImg src={roof[index]} alt="Energy Reduced" />
              <NavigateContainer>
                <RightArrowContainer onClick={next}>
                  <SvgArrow />
                </RightArrowContainer>
                <LeftArrowContainer onClick={previous}>
                  <SvgArrow />
                </LeftArrowContainer>
              </NavigateContainer>
            </GalleryContainer>
          ) : (
            <GalleryContainer>
              <CarouselImg src={ev[index]} alt="Energy Reduced" />
              <NavigateContainer>
                <RightArrowContainer onClick={next}>
                  <SvgArrow />
                </RightArrowContainer>
                <LeftArrowContainer onClick={previous}>
                  <SvgArrow />
                </LeftArrowContainer>
              </NavigateContainer>
            </GalleryContainer>
          )}
        </Card>
      </ProjectContainer>
    </OuterContainer>
  );
};

export default ProjectSection;

{
  /* <InfoContainer>
              <InfoTextContainer>
                <InfoTitle>
                  17.5 Kilowatt System installed in St. Petersburg, Florida
                </InfoTitle>
                <InfoDescription>
                  This system saved the customer $127 per month while offsetting
                  their entire electric bill
                </InfoDescription>
              </InfoTextContainer>
              <BottomInfoContainer>
                <LinkButton onClick={() => setSelected(3)}>
                  <ButtonText>See Info</ButtonText>
                </LinkButton>
                <InfoImage onClick={() => setSelected(2)}>
                  <Img src={Pic} alt="Solar System" />
                </InfoImage>
              </BottomInfoContainer>
            </InfoContainer> */
}

// <FactsContainer>
//   <FactsText>
//     Solar power in Florida has been increasing, as the cost of solar
//     power systems using photovoltaics (PV) has decreased in recent
//     years.
//   </FactsText>
//   <FactsText>
//     Solar energy is the state's most abundant energy resource and
//     estimates have placed the state's potential at 2,902 GW an
//     amount much larger than the state and countries's total
//     electricity consumption.
//   </FactsText>
// </FactsContainer>
