import React from "react";
import {
  ContactContainer,
  LeftContainer,
  Title,
  RightContainer,
  Container,
  Icon,
  Form,
  FormInput,
  FormTextArea,
  FormButton,
  OuterContainer,
  FooterContainer,
  Address,
  Number,
  Links,
  ContactTitle,
  InputContainer,
  NavBtn,
  NavLinks,
  BtnContainer,
} from "./style";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";

const NewContactSection = () => {
  return (
    <OuterContainer id="NewContact">
      <ContactContainer>
        <LeftContainer>
          <Title>GET A FREE PERSONALIZED QUOTE -</Title>
          <FooterContainer>
            <ContactTitle>
              Start your journey to a better energy experience.
            </ContactTitle>
            {/*
            <Container>
              <Address>License Number: EC13008259</Address>
              <Number>813-467-6859</Number>
              <Links>
                <Icon
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/advancedintegratedsolar/"
                >
                  <TiSocialInstagram />
                </Icon>
                <Icon
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/profile.php?id=100089648901482"
                >
                  <TiSocialFacebook />
                </Icon>
                {/*<Icon>
                  <TiSocialYoutube />
                </Icon>*/}
            {/*<Icon>
                  <TiSocialTwitter />
                </Icon>*/}
            {/*</Links>
            </Container>
            <BtnContainer>
              <NavBtn>
                <NavLinks
                  to="/quote"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-40}
                >
                  EV Charger Installation Quote
                </NavLinks>
              </NavBtn>
            </BtnContainer>*/}
          </FooterContainer>
        </LeftContainer>
        {/*
            <Banner src={Night} alt="Not here" />*/}
        <RightContainer>
          {/*<Topic>HAVE A PROJECT</Topic>*/}
          <Form
            name="contact v1"
            method="post"
            data-netlify="true"
            onSubmit="submit"
          >
            <FormInput type="hidden" name="form-name" value="contact v1" />
            {/*<label>Inquire</label>*/}
            <InputContainer>
              <FormInput type="text" name="name" placeholder="Name" required />
            </InputContainer>
            <InputContainer>
              <FormInput
                type="email"
                name="email"
                placeholder="Email"
                required
              />
            </InputContainer>
            <InputContainer>
              <FormInput
                type="text"
                name="phone"
                placeholder="Phone Number"
                required
              />
            </InputContainer>
            <InputContainer>
              <FormInput
                type="text"
                name="address"
                placeholder="Address"
                required
              />
            </InputContainer>
            <FormButton type="submit">Send Message</FormButton>
          </Form>
        </RightContainer>
      </ContactContainer>
    </OuterContainer>
  );
};

export default NewContactSection;
