import styled from "styled-components";

export const OuterContainer = styled.div`
  background: rgb(10, 11, 11);
  display: flex;
  justify-content: center;
  height: 100vh;
  min-height: 1080px;
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const ProjectContainer = styled.div`
  background: radial-gradient(30% 50% at top right, #454f4a 0%, #202f28 100%);
  display: flex;
  max-width: 2560px;

  margin: 0 auto 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1130px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  position: relative;
  z-index: 2;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: 1505px) {
    left: 6%;
  }

  @media screen and (max-width: 1130px) {
    flex-direction: row;
    width: 90%;
    height: 3%;
    left: 5%;
    justify-content: space-around;
  }

  @media screen and (max-width: 880px) {
    width: 99%;
    left: 0.5%;
    height: 1.5%;
    justify-content: space-between;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 90%;
  border-bottom: solid 2px rgba(255, 255, 255, 0.4);
  padding-bottom: 4rem;

  @media screen and (max-width: 2025px) {
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 1505px) {
    width: 100%;
  }

  @media screen and (max-width: 1130px) {
    width: 24%;
    border-bottom: none;
    border-right: solid 2px rgba(255, 255, 255, 0.4);
    padding-bottom: 0;
  }

  @media screen and (max-width: 880px) {
    border-right: none;
    justify-content: center;
  }
`;

export const Number = styled.div`
  background: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5rem;
  font-weight: bold;
  padding-top: 2.25rem;

  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 2400px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 2025px) {
    font-size: 2.25rem;
    padding-top: 2rem;
  }

  @media screen and (max-width: 1760px) {
    font-size: 2rem;
    padding-top: 1.25rem;
  }

  @media screen and (max-width: 1360px) {
    font-size: 1.75rem;
    padding-top: 1.25rem;
  }

  @media screen and (max-width: 1130px) {
    display: none;
  }
`;

export const Title = styled.div`
  background: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 6.5rem;
  padding: 0 0 1rem 1.85rem;
  font-weight: bold;

  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 2400px) {
    font-size: 5.5rem;
  }

  @media screen and (max-width: 2025px) {
    font-size: 4.75rem;
  }

  @media screen and (max-width: 1760px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 1360px) {
    font-size: 3.25rem;
  }

  @media screen and (max-width: 1130px) {
    font-size: 1.75rem;
    padding: 0 0 0 0.5rem;
  }

  @media screen and (max-width: 1050px) {
    font-size: 1.5rem;
    padding: 0 0 0 0.25rem;
  }

  @media screen and (max-width: 880px) {
    font-size: 1.15rem;
    padding: 0;
  }

  @media screen and (max-width: 565px) {
    font-size: 0.85rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 0.715rem;
  }
`;

export const Card = styled.div`
  position: relative;
  z-index: 2;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 45%;
  display: flex;
  flex-direction: column;
  border-radius: 1vh;
  background: rgba(255, 255, 255, 0.03);

  @media screen and (max-width: 1130px) {
    top: 15%;
    left: 5%;
    height: 60%;
    width: 90%;
  }

  @media screen and (max-width: 880px) {
    width: 95%;
    left: 2.5%;
  }
`;

export const CardTitleContainer = styled.div`
  background: rgba(255, 255, 255, 0.05);
  height: 17.5%;
  width: 100%;
  border-bottom: solid #7b847f;
`;

export const CardInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 880px) {
    padding-left: 0;
    justify-contents: space-evenly;
  }
`;

export const CardTitle = styled.div`
  font-size: 2rem;
  color: ${({ active }) =>
    active ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.6)"};
  font-weight: ${({ active }) => (active ? "bold" : "")};
  border-right: ${({ noBorder }) => (noBorder ? "none" : "solid 2px #7b847f")};
  padding: 0 3rem 0 3rem;
  line-height: 100%;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  height: 4rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1700px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 1.325rem;
    padding: 0 1.85rem 0 1.85rem;
  }

  @media screen and (max-width: 550px) {
    font-size: 0.95rem;
    padding: 0 0.85rem 0 0.85rem;
  }
`;

export const InfoContainer = styled.div`
  height: 82.5%;
`;

export const InfoTextContainer = styled.div`
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const InfoTitle = styled.div`
  font-size: 4rem;
  color: white;
  line-height: 100%;
  font-family: "Poppins", sans-serif;
  padding: 0 5rem 0 5rem;

  @media screen and (max-width: 2400px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 1875px) {
    font-size: 2.5rem;
    line-height: 120%;
  }

  @media screen and (max-width: 1505px) {
    font-size: 2.15rem;
    padding: 0 3.25rem 0 3.25rem;
  }

  @media screen and (max-width: 880px) {
    font-size: 1.5rem;
    padding: 0 1rem 0 1rem;
  }
`;

export const InfoDescription = styled.div`
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 100%;
  font-family: "Poppins", sans-serif;
  padding: 0 5rem 0 5rem;
  margin-top: -4rem;

  @media screen and (max-width: 2025px) {
    font-size: 1.85rem;
  }

  @media screen and (max-width: 1875px) {
    font-size: 1.5rem;
    line-height: 120%;
  }

  @media screen and (max-width: 1505px) {
    font-size: 1.35rem;
    padding: 0 3.25rem 0 3.25rem;
  }

  @media screen and (max-width: 880px) {
    margin-top: -2rem;
    font-size: 1.15rem;
    padding: 0 1rem 0 1rem;
  }
`;

export const BottomInfoContainer = styled.div`
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LinkButton = styled.div`
  height: 5rem;
  width: 15rem;
  background: #ecc256;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5vh;
  margin-left: 5rem;
  cursor: pointer;

  @media screen and (max-width: 1760px) {
    height: 4rem;
    width: 12rem;
  }

  @media screen and (max-width: 1505px) {
    height: 3rem;
    width: 9rem;
  }

  @media screen and (max-width: 880px) {
    margin-left: 1rem;
    height: 2.5rem;
    width: 8rem;
  }
`;

export const ButtonText = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 1.65rem;
  font-weight: bold;

  @media screen and (max-width: 1760px) {
    font-size: 1.35rem;
  }

  @media screen and (max-width: 1505px) {
    font-size: 1.15rem;
  }
`;

export const InfoImage = styled.div`
  position: relative;
  height: 17.5rem;
  width: 17.5rem;
  border-radius: 50%;
  background: linear-gradient(
    35deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  margin-right: 5rem;
  cursor: pointer;

  @media screen and (max-width: 1760px) {
    height: 15rem;
    width: 15rem;
  }

  @media screen and (max-width: 1505px) {
    top: -20%;
    left: 5%;
  }

  @media screen and (max-width: 1360px) {
    height: 13rem;
    width: 13rem;
  }

  @media screen and (max-width: 880px) {
    height: 9.5rem;
    width: 12rem;
    left: 12.5%;
  }
`;

export const Img = styled.img`
  position: relative;
  top: 5%;
  left: 5%;
  height: 90%;
  width: 90%;
  border-radius: 50%;
  object-fit: cover;
`;

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0 0 0;
  height: 55%;
`;

export const NavigateContainer = styled.div`
  width: 100%;
  height: 3rem;
  position: relative;
  top: -40%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 1vh 0 0;
`;

export const RightArrowContainer = styled.div`
  cursor: pointer;
  rotate: 180deg;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  padding: 1rem 0 1rem 0;
  background-color: rgba(115, 115, 115, 0.5);
  border-radius: 1vh;

  :hover {
    background-color: rgba(115, 115, 115, 0.9);
  }

  @media screen and (max-width: 800px) {
    margin-left: 0.15rem;
  }
`;

export const LeftArrowContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 1rem 0 1rem 0;
  background-color: rgba(115, 115, 115, 0.5);
  border-radius: 1vh;

  :hover {
    background-color: rgba(115, 115, 115, 0.9);
  }

  @media screen and (max-width: 800px) {
    margin-right: 0.15rem;
  }
`;

export const CarouselImg = styled.img`
  position: relative;
  top: 3%;
  left: 3%;
  height: 94%;
  width: 94%;
  border-radius: 1vh;
  object-fit: cover;

  @media screen and (max-width: 800px) {
  }
`;

export const DataContainer = styled.div`
  position: relative;
  left: 7.5%;
  z-index: 2;
  margin-top: 2rem;
  width: 30%;
  height: 300px;
  display: flex;
  border-radius: 0.5vh;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border: solid 2px transparent;
  background-clip: padding-box;
  box-shadow: -5px 0px 20px rgba(118, 184, 35, 0.2);

  @media screen and (max-width: 2250px) {
    width: 40%;
    height: 65%;
  }

  @media screen and (max-width: 800px) {
    left: 5%;
    width: 150%;
    height: 90%;
  }
`;

export const FirstContainer = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export const SecondContainer = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const DataAltContainer = styled.div`
  position: relative;
  right: 7.5%;
  z-index: 2;
  margin-top: 2rem;
  width: 30%;
  height: 300px;
  display: flex;
  border-radius: 0.5vh;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border: solid 2px transparent;
  background-clip: padding-box;
  box-shadow: 5px 0px 20px rgba(118, 184, 35, 0.2);

  @media screen and (max-width: 2250px) {
    width: 40%;
    height: 65%;
  }

  @media screen and (max-width: 800px) {
    right: 5%;
    width: 150%;
    height: 90%;
  }
`;

export const HeaderAlt = styled.div`
  color: white;
  font-size: 2.75rem;
  width: 100%;
  text-align: right;
  margin-left: 1rem;
  height: 30%;

  @media screen and (max-width: 2800px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 2250px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 1550px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 1rem;
    width: 120%;
  }
`;
export const TextAlt = styled.div`
  color: #76b820;
  font-size: 2rem;
  width: 50%;
  text-align: right;
  margin-left: 1rem;
  height: 30%;

  @media screen and (max-width: 2800px) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: 2250px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
`;

export const Header = styled.div`
  color: white;
  font-size: 3rem;
  width: 95%;
  text-align: right;
  margin-right: 1rem;
  height: 35%;

  @media screen and (max-width: 2800px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 2250px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 1rem;
    width: 120%;
  }
`;
export const Text = styled.div`
  color: #76b820;
  font-size: 2rem;
  width: 50%;
  text-align: right;
  margin-right: 1rem;
  height: 35%;

  @media screen and (max-width: 2800px) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: 2250px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1550px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
`;

export const Subtext = styled.div`
  color: white;
  font-size: 1rem;

  @media screen and (max-width: 1100px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.6rem;
  }
`;

export const FactsContainer = styled.div`
  position: relative;
  top: 2.5%;
  height: 80%;
  width: 100%;
  padding: 2rem;
`;

export const FactsText = styled.div`
  color: lightgrey;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  padding-top: 0.75rem;

  @media screen and (max-width: 1400px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.15rem;
  }
`;
