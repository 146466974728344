import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";

export const OuterContainer = styled.div`
  background: rgb(10, 11, 11);
  display: flex;
  justify-content: center;
  height: 100vh;
  min-height: 1080px;
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const ServicesContainer = styled.div`
  background: white;
  display: flex;
  max-width: 2560px;
  margin: 0 auto 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1450px) {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  top: 8%;
  left: 12.5%;
  z-index: 2;
  width: 30%;
  height: 35%;

  @media screen and (max-width: 1450px) {
    top: 2%;
    left: 12.5%;
    height: 20%;
  }

  @media screen and (max-width: 700px) {
    left: 5%;
  }
`;

export const Title = styled.div`
  font-size: 7rem;
  color: black;

  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1920px) {
    font-size: 5rem;
  }

  @media screen and (max-width: 1450px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 1150px) {
    font-size: 2.25rem;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2.5rem;
  padding: 1rem 2rem 1rem 2rem;
  height: 12.5%;
  width: 35%;
  border-radius: 0.5vh;
  background: #ecc256;
  text-align: center;

  @media screen and (max-width: 1450px) {
    margin-top: 1.75rem;
    height: 15%;
    width: 30%;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  @media screen and (max-width: 1150px) {
    width: 5.5rem;
  }

  @media screen and (max-width: 700px) {
    margin-top: 1rem;
  }
`;

export const ButtonText = styled(LinkS)`
  line-height: 100%;
  font-family: "Poppins", sans-serif;
  color: black;
  cursor: pointer;
`;

export const CardContainer = styled.div`
  position: relative;
  top: 7.5%;
  left: 10%;
  height: 40%;
  width: 50%;
  z-index: 2;
  border-radius: 0.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 3rem;

  @media screen and (max-width: 1650px) {
    width: 55%;
    left: 3%;
  }

  @media screen and (max-width: 1450px) {
    left: 22.5%;
    top: -5%;
  }

  @media screen and (max-width: 1150px) {
    width: 75%;
    left: 12.5%;
  }

  @media screen and (max-width: 850px) {
    width: 90%;
    left: 3%;
  }

  @media screen and (max-width: 700px) {
    width: 95%;
    padding-left: 0rem;
  }
`;

export const UpperContainer = styled.div`
  width: 95%;
  height: 31%;
  border: solid 1px lightgrey;
  border-radius: 0.5vh;
  padding: 1rem 0 0 1rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1650px) {
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    padding: 1rem 0 0 0.5rem;
  }
`;

export const MiddleContainer = styled.div`
  width: 95%;
  height: 31%;
  border: solid 1px lightgrey;
  border-radius: 0.5vh;
  padding: 1rem 0 0 1rem;

  @media screen and (max-width: 1650px) {
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    padding: 1rem 0 0 0.5rem;
  }
`;

export const LowerContainer = styled.div`
  width: 95%;
  height: 31%;
  border: solid 1px lightgrey;
  border-radius: 0.5vh;
  padding: 1rem 0 0 1rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1650px) {
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    padding: 1rem 0 0 0.5rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Text = styled.div`
  color: black;
  font-size: 1.75rem;
  width: 100%;

  line-height: 100%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1920px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1450px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 1150px) {
    font-size: 1.15rem;
  }

  @media screen and (max-width: 700px) {
    font-size: 1rem;
  }
`;

export const SubText = styled.div`
  color: #979797;
  font-size: 1.25rem;
  width: 80%;
  height: 20%;
  margin: 0.5rem 0 0 1rem;

  line-height: 120%;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1800px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 700px) {
    font-size: 0.8rem;
    margin: 0.35rem 0 0 0.35rem;
    width: 80%;
  }
`;

export const LearnMore = styled.div`
  position: relative;
  color: #c0a453;
  font-size: 1rem;
  text-align: center;
  border: solid 1px #c0a453;
  width: 7rem;
  padding: 0.5rem;
  border-radius: 0.5vh;
  background: #fbf4dd;
  cursor: pointer;
  line-height: 100%;
  font-family: "Poppins", sans-serif;
  top: 60%;
  margin: 0 1rem 0 0;

  @media screen and (max-width: 1650px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 1450px) {
  }

  @media screen and (max-width: 700px) {
    font-size: 0.7rem;
    margin: 0 0.5rem 0 0;
    width: 5rem;
  }
`;

export const ContainerOne = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: 30%;
`;

export const ContainerTwo = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: 15%;
`;

export const ContainerThree = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Footer = styled.div`
  font-size: 2rem;
  color: #76b820;
  border-bottom: solid #76b820;

  @media screen and (max-width: 1920px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 1450px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1150px) {
    font-size: 1.25rem;
  }
`;

export const Img = styled.img`
  position: absolute;
  left: 11%;
  width: 78%;
  height: 100%;
  filter: contrast(150%);
  object-fit: cover;

  @media screen and (max-width: 1150px) {
    left: 2.5%;
    width: 95%;
  }
`;

export const NavBtnLink = styled(LinkR)`
  text-decoration: none;
`;
