import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const OuterContainer = styled.div`
  background: #070d18;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;
  min-height: 1000px;

  @media screen and (max-width: 500px) {
    background: #070d18;
  }
`;

export const InfoContainer = styled.div`
  position: relative;
  z-index: 10;
  top: 2%;
  margin: 0 1rem 0 1rem;
  border: solid 2px rgba(77, 255, 255, 0.6);
  border-radius: 1vh;
  background-color: rgba(77, 255, 255, 0.3);
  padding: 0.35rem;
  width: 100%;
  max-width: 800px;
`;

export const Text = styled.div`
  font-family: "Poppins", sans-serif;
  color: white;
`;

export const Text2 = styled.div`
  text-align: right;
  font-family: "Poppins", sans-serif;
  margin-top: 0.25rem;
  padding-right: 0.5rem;
  color: white;
`;

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  margin: 0 auto 0 auto;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Img = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  z-index: 2;
  height: 80%;
  object-fit: cover;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const Img2 = styled.img`
  visibility: hidden;

  @media screen and (max-width: 650px) {
    position: relative;
    z-index: 5;
    visibility: visible;
    height: 75%;
    width: 100%;
    filter: contrast(125%);
    object-fit: cover;
  }
`;

export const BottomInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top: solid 4px lightgrey;
  height: 25%;
  position: relative;
  z-index: 10;
  top: 64%;
  margin: 1rem;
  max-width: 800px;

  background-color: rgba(25, 25, 25, 0.95);

  @media screen and (max-width: 500px) {
    border-top: solid 2px lightgrey;
    height: 17.5%;
    position: relative;
    z-index: 10;
    top: 25%;
    margin: 1rem;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
  }
`;

export const OneContainer = styled.div`
  margin: 1rem 1rem 0 1rem;
`;

export const TitleText = styled.div`
  margin: 0.5rem 0.25rem 0 0.25rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: white;
`;

export const InnerText = styled.div`
  margin: 0.5rem 0.25rem 0 0.25rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  color: lightgrey;
`;

export const PurchaseContainer = styled.div`
  margin: 1rem;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    margin: 2.5rem 0 0 0;
    border: solid 2px lightgrey;
    border-radius: 0.5vh;
  }
`;

export const BuyText = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  padding: 0.5rem;
  color: white;

  @media screen and (max-width: 500px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 0.5rem 1rem;
  @media screen and (max-width: 500px) {
    margin: 0;
  }
`;

export const NavBtn = styled.nav`
  margin: 0.85rem 0 0 0;
  border-radius: 0.5vh;
  background: rgb(10, 11, 11);
  white-space: nowrap;

  width: 48%;

  color: white;
  font-size: 16px;
  outline: none;
  border: solid white 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0.5em 0.5rem -0.4rem;
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
    background: none;
    color: white;
  }
`;

export const NavLinks = styled(LinkR)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 15px;
  height: 100%;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;

  &.active {
    border-bottom: 2px solid white;
  }

  @media screen and (max-width: 2500px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1900px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1275px) {
    font-size: 13px;

    &.active {
      border-bottom: none;
    }
  }
`;
