import React, { useState } from "react";
import {
  OuterContainer,
  GlassContainer,
  TitleContainer,
  TitleText,
  BorderBottomContainer,
  BorderBottomLine,
  BorderBottomLine2,
  FormContainer,
  Label,
  AnswerSection,
  SecondAnswerSection,
  SecondLabel,
  QuoteButton,
  SubmitButton,
  ButtonFirstContainer,
  ButtonContainer,
  Select,
  Option,
  UploadContainer,
  NavBtn,
  NavLinks,
  FormInput,
} from "./style";
import Solar from "./solar.js";

const QuoteSection = () => {
  const [selected, setSelected] = useState(true);

  function handleButtonClick(buttonName) {
    if (buttonName === "button1") {
      setSelected(true);
    } else if (buttonName === "button2") {
      setSelected(false);
    }
  }

  const [formNumber, setformNumber] = useState(1);
  function handleNext() {
    setformNumber((formNumber) => formNumber + 1);
  }
  function handlePrevious() {
    setformNumber((formNumber) => formNumber - 1);
  }

  console.log(formNumber);

  const [selectedbuildingType, setSelectedbuildingType] =
    useState("commercial");
  function handlebuildingTypeChange(event) {
    setSelectedbuildingType(event.target.value);
  }
  const [selectedPropertyOwner, setSelectedPropertyOwner] = useState("yes");
  function handlePropertyOwner(event) {
    setSelectedPropertyOwner(event.target.value);
  }
  const [selectedFamilyQuote, setSelectedFamilyQuote] = useState("unselected");
  function handleFamilyQuote(event) {
    setSelectedFamilyQuote(event.target.value);
  }
  const [selectedCharger, setSelectedCharger] = useState("tesla");
  function handleCharger(event) {
    setSelectedCharger(event.target.value);
  }
  const [selectedBreaker, setSelectedBreaker] = useState("200 amp");
  function handleBreaker(event) {
    setSelectedBreaker(event.target.value);
  }
  const [selectedSpace, setSelectedSpace] = useState("Yes");
  function handleSpace(event) {
    setSelectedSpace(event.target.value);
  }
  const [selectedLocation, setSelectedLocation] = useState("Indoor");
  function handleLocation(event) {
    setSelectedLocation(event.target.value);
  }
  const [selectedMilitary, setSelectedMilitary] = useState("No");
  function handleMilitary(event) {
    setSelectedMilitary(event.target.value);
  }

  const [myFile, setMyFile] = useState(null);

  const handleFileChange = (event) => {
    setMyFile(event.target.files[0]);
  };

  // console.log(selectedbuildingType);
  // console.log(selectedPropertyOwner);
  // console.log(selectedFamilyQuote);
  // console.log(selectedCharger);
  // console.log(selectedBreaker);

  return (
    <OuterContainer bg={selected}>
      <NavBtn>
        <NavLinks to="/">Back</NavLinks>
      </NavBtn>
      <GlassContainer>
        <TitleContainer>
          <TitleText onClick={() => handleButtonClick("button1")}>
            Solar Energy
          </TitleText>
          <TitleText onClick={() => handleButtonClick("button2")}>
            EV Charger
          </TitleText>
        </TitleContainer>
        <BorderBottomContainer>
          <BorderBottomLine selected={selected}></BorderBottomLine>
          <BorderBottomLine2 selected={selected}></BorderBottomLine2>
        </BorderBottomContainer>
        {selected ? (
          <Solar />
        ) : (
          <FormContainer
            name="charger v1"
            method="post"
            data-netlify="true"
            onSubmit="submit"
          >
            <input type="hidden" name="form-name" value="charger v1" />
            <>
              <Label>Is this a residential or commercial property?</Label>
              <AnswerSection>
                <Select
                  name="buildingType"
                  value={selectedbuildingType}
                  onChange={handlebuildingTypeChange}
                >
                  <Option value="commercial">Commercial</Option>
                  <Option value="residential">Residential</Option>
                </Select>
              </AnswerSection>
              <Label>Are you the property owner?</Label>
              <AnswerSection>
                <Select
                  name="propertyOwner"
                  value={selectedPropertyOwner}
                  onChange={handlePropertyOwner}
                  disabled={selectedPropertyOwner === "none"}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </AnswerSection>
              {selectedPropertyOwner === "no" && (
                <>
                  <SecondLabel>
                    Is this quote for a friend or relative?
                  </SecondLabel>

                  <SecondAnswerSection>
                    <Select
                      name="FamilyQuote"
                      value={selectedFamilyQuote}
                      onChange={handleFamilyQuote}
                      disabled={selectedFamilyQuote === "none"}
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </SecondAnswerSection>
                </>
              )}
              <Label>What type of charger are you installing?</Label>
              <AnswerSection>
                <Select
                  name="Charger"
                  value={selectedCharger}
                  onChange={handleCharger}
                >
                  <Option value="tesla">Tesla</Option>
                  <Option value="other">Other</Option>
                </Select>
              </AnswerSection>
              <Label>What size main breaker do you have?</Label>
              <AnswerSection>
                <Select
                  name="Breaker"
                  value={selectedBreaker}
                  onChange={handleBreaker}
                >
                  <Option value="200 amp">200 amp</Option>
                  <Option value="150 amp">150 amp</Option>
                  <Option value="Less than 150 amp">Less than 150 amp</Option>
                </Select>
              </AnswerSection>
              {selectedBreaker === "Less than 150 amp" && (
                <>
                  <SecondLabel>
                    Main panel service upgrade required esitmated cost: $3,500
                  </SecondLabel>
                </>
              )}
              {/*<ButtonFirstContainer>
                <QuoteButton onClick={handleNext}>Next</QuoteButton>
              </ButtonFirstContainer>*/}
            </>

            <>
              <Label>
                Do you have open space in your main electrical panel? (2
                consecutive spots must be open)
              </Label>
              <AnswerSection>
                <Select
                  name="Space"
                  value={selectedSpace}
                  onChange={handleSpace}
                  disabled={selectedSpace === "none"}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </AnswerSection>
              {selectedSpace === "no" && (
                <>
                  <SecondLabel>
                    Way may still be able to help. We will use site photos to
                    determine if we can perform the job
                  </SecondLabel>
                </>
              )}
              <Label>
                Will this be placed indoor (inside the garage) or outdoor (on
                the same side as the meter)?
              </Label>
              <AnswerSection>
                <Select
                  name="Location"
                  value={selectedLocation}
                  onChange={handleLocation}
                >
                  <Option value="indoor">Indoor</Option>
                  <Option value="outdoor">Outdoor</Option>
                </Select>
              </AnswerSection>
              <Label>
                Are you an active duty service member, or veteran of the United
                States Military?
              </Label>
              <AnswerSection>
                <Select
                  name="Military"
                  value={selectedMilitary}
                  onChange={handleMilitary}
                >
                  <Option value="no">No</Option>
                  <Option value="yes">Yes</Option>
                </Select>
              </AnswerSection>
              {/*<ButtonContainer>
                <QuoteButton onClick={handlePrevious}>Previous</QuoteButton>
                <QuoteButton onClick={handleNext}>Next</QuoteButton>
              </ButtonContainer>*/}
            </>

            <>
              <Label>
                To be scheduled for installation, we will need site photos for
                the project. Please submit the following:
              </Label>
              <Label>1. Installation address</Label>
              <Label>
                2. A clear photo of the inside of the main electrical breaker
                and panel door{" "}
                <span style={{ color: "lightgrey" }}>
                  (Images should not exceed 5MB)
                </span>
              </Label>
              <UploadContainer>
                <input
                  name="ElectricBreaker"
                  type="file"
                  onChange={handleFileChange}
                />
              </UploadContainer>

              <Label>
                3. A clear photo of the area you would like the charger
                installed in{" "}
                <span style={{ color: "lightgrey" }}>
                  (Images should not exceed 5MB)
                </span>
              </Label>
              <UploadContainer>
                <input name="InstallLocation" type="file" />
              </UploadContainer>
              <FormInput
                type="email"
                name="email"
                placeholder="Email"
                required
              />
              {/*<QuoteButton onClick={handlePrevious}>Previous</QuoteButton>*/}
              <ButtonContainer>
                <SubmitButton type="submit">Submit</SubmitButton>
              </ButtonContainer>
            </>
          </FormContainer>
        )}
      </GlassContainer>
    </OuterContainer>
  );
};

export default QuoteSection;
