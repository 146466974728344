import React, { useState } from "react";
import styled from "styled-components";
import HeroSection from "../components/Hero";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import AboutSection from "../components/About";
import ServicesSection from "../components/Services";
import ContactSection from "../components/Contact";
import NewContactSection from "../components/NewContact";
import ProjectSection from "../components/Projects";
import FAQSection from "../components/FAQ";
// import FooterSection from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Section isOpen={isOpen}>
        <HeroSection />
        <NewContactSection />
        <FAQSection />
        <ServicesSection />
        <ProjectSection />
        <ContactSection />
        {/*<FooterSection />*/}
      </Section>
    </>
  );
};

export const Section = styled.div`
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "0" : "100%")};
`;

// @media screen and (max-width: 1365px) {
//   display: none;
// }

// export const BodySection = styled.div`
//   background-image: radial-gradient(
//       ellipse at bottom right,
//       #0c1404 0%,
//       #1a2a08 75%,
//       transparent 100%
//     ),
//     radial-gradient(ellipse at top left, #76b820, transparent);
//   height: 100%;
//   width: 80%;
//   border-radius: 0.5vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;
//
// export const InnerSection = styled.div`
//   height: 100%;
//   width: 80%;
//   border-radius: 0.5vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

export default Home;
