import React from "react";
import {
  ContactContainer,
  LeftContainer,
  Title,
  RightContainer,
  Container,
  Icon,
  Form,
  FormInput,
  FormTextArea,
  FormButton,
  OuterContainer,
  FooterContainer,
  Address,
  Number,
  Links,
  ContactTitle,
  InputContainer,
  NavBtn,
  NavLinks,
  BtnContainer,
  Email,
  OutroContainer,
  OutLine,
  OutTopContain,
  OutBottomContain,
} from "./style";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";

const ContactSection = () => {
  const email = "info@ais.solar";
  const subject = "New Solar System";
  const body = "Ask us how we can save you money on your energy needs!";

  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  return (
    <OuterContainer id="Contact">
      <ContactContainer>
        <LeftContainer>
          {/* <Title></Title> */}
          <FooterContainer>
            <ContactTitle>CONTACTS</ContactTitle>
            <Container>
              <Address>License Number: EC13008259</Address>
              <Number>813-467-6859</Number>
              <Email>
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href={mailtoLink}
                >
                  info@ais.solar
                </a>
              </Email>
              <Links>
                <Icon
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/advancedintegratedsolar/"
                >
                  <TiSocialInstagram />
                </Icon>
                <Icon
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/profile.php?id=100089648901482"
                >
                  <TiSocialFacebook />
                </Icon>
                {/*<Icon>
                  <TiSocialYoutube />
                </Icon>*/}
                {/*<Icon>
                  <TiSocialTwitter />
                </Icon>*/}
              </Links>
            </Container>
            <BtnContainer>
              <NavBtn>
                <NavLinks
                  to="/quote"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-40}
                >
                  EV Charger Installation Quote
                </NavLinks>
              </NavBtn>
            </BtnContainer>
          </FooterContainer>
          <OutroContainer>
            <OutLine></OutLine>
            <OutTopContain>
              All Rights Reserved, Advanced Integrated Solar 2024
            </OutTopContain>
            <OutBottomContain
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.everdarksoftware.com"
            >
              Powered By: EverDark Software
            </OutBottomContain>
          </OutroContainer>
        </LeftContainer>
        {/*
            <Banner src={Night} alt="Not here" />*/}
        {/* <RightContainer>
          <Topic>HAVE A PROJECT</Topic>
           <Form
            name="contact v1"
            method="post"
            data-netlify="true"
            onSubmit="submit"
          >
            <FormInput type="hidden" name="form-name" value="contact v1" />
            <label>Inquire</label>
            <InputContainer>
              <FormInput type="text" name="name" placeholder="Name" required />
            </InputContainer>
            <InputContainer>
              <FormInput
                type="email"
                name="email"
                placeholder="Email"
                required
              />
            </InputContainer>
            <FormTextArea
              name="message"
              rows="5"
              placeholder="Message"
              required
            />
            <FormButton type="submit">Send Message</FormButton>
          </Form> 
        </RightContainer> */}
      </ContactContainer>
    </OuterContainer>
  );
};

export default ContactSection;
