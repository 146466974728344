import React, { useState } from "react";
import {
  OuterContainer,
  GlassContainer,
  TitleContainer,
  TitleText,
  BorderBottomContainer,
  BorderBottomLine,
  BorderBottomLine2,
  FormContainer,
  Label,
  AnswerSection,
  SecondAnswerSection,
  SecondLabel,
  QuoteButton,
  SubmitButton,
  ButtonFirstContainer,
  ButtonContainer,
  Select,
  Option,
  UploadContainer,
  NavBtn,
  NavLinks,
  FormInput,
} from "./style";

const Solar = () => {
  const [selected, setSelected] = useState(false);

  function handleButtonClick(buttonName) {
    if (buttonName === "button1") {
      setSelected(true);
    } else if (buttonName === "button2") {
      setSelected(false);
    }
  }

  const [formNumber, setformNumber] = useState(1);
  function handleNext() {
    setformNumber((formNumber) => formNumber + 1);
  }
  function handlePrevious() {
    setformNumber((formNumber) => formNumber - 1);
  }

  console.log(formNumber);

  const [selectedbuildingType, setSelectedbuildingType] =
    useState("commercial");
  function handlebuildingTypeChange(event) {
    setSelectedbuildingType(event.target.value);
  }
  const [selectedPropertyOwner, setSelectedPropertyOwner] = useState("yes");
  function handlePropertyOwner(event) {
    setSelectedPropertyOwner(event.target.value);
  }
  const [selectedFamilyQuote, setSelectedFamilyQuote] = useState("unselected");
  function handleFamilyQuote(event) {
    setSelectedFamilyQuote(event.target.value);
  }
  const [selectedCharger, setSelectedCharger] = useState("tesla");
  function handleCharger(event) {
    setSelectedCharger(event.target.value);
  }
  const [selectedBreaker, setSelectedBreaker] = useState("200 amp");
  function handleBreaker(event) {
    setSelectedBreaker(event.target.value);
  }
  const [selectedSpace, setSelectedSpace] = useState("Yes");
  function handleSpace(event) {
    setSelectedSpace(event.target.value);
  }
  const [selectedLocation, setSelectedLocation] = useState("Indoor");
  function handleLocation(event) {
    setSelectedLocation(event.target.value);
  }
  const [selectedMilitary, setSelectedMilitary] = useState("No");
  function handleMilitary(event) {
    setSelectedMilitary(event.target.value);
  }

  const [myFile, setMyFile] = useState(null);

  const handleFileChange = (event) => {
    setMyFile(event.target.files[0]);
  };

  /////////
  const initialPlaceholders = {
    text: "County Name",
    email: "Email",
    provider: "Electric Utilities Provider",
    dollar: "$",
  };

  const [placeholders, setPlaceholders] = useState(initialPlaceholders);

  const handleFocus = (field) => {
    setPlaceholders((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (!value) {
      setPlaceholders((prev) => ({
        ...prev,
        [name]: initialPlaceholders[name],
      }));
    }
  };
  //////

  return (
    <FormContainer
      name="charger v1"
      method="post"
      data-netlify="true"
      onSubmit="submit"
    >
      <input type="hidden" name="form-name" value="charger v1" />
      <>
        <Label>Are you the property owner?</Label>
        <AnswerSection>
          <Select
            name="propertyOwner"
            value={selectedPropertyOwner}
            onChange={handlePropertyOwner}
            disabled={selectedPropertyOwner === "none"}
          >
            <Option value="yes">Yes</Option>
            <Option value="no">No</Option>
          </Select>
        </AnswerSection>
        {selectedPropertyOwner === "no" && (
          <>
            <SecondLabel>Is this quote for a friend or relative?</SecondLabel>

            <SecondAnswerSection>
              <Select
                name="FamilyQuote"
                value={selectedFamilyQuote}
                onChange={handleFamilyQuote}
                disabled={selectedFamilyQuote === "none"}
              >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </SecondAnswerSection>
          </>
        )}
        <Label>What type of roof do you have?</Label>
        <AnswerSection>
          <Select
            name="Charger"
            value={selectedCharger}
            onChange={handleCharger}
          >
            <Option value="Asphalt Shingles">Asphalt Shingles</Option>
            <Option value="Metal">Metal</Option>
            <Option value="Tile">Tile</Option>
            <Option value="Flat">Flat</Option>
          </Select>
        </AnswerSection>
        <Label>How old is your roof?</Label>
        <AnswerSection>
          <Select
            name="Breaker"
            value={selectedBreaker}
            onChange={handleBreaker}
          >
            <Option value="less than 1 year">less than 1 year</Option>
            <Option value="less than 10 years">less than 10 years</Option>
            <Option value="over 10 years">over 10 years</Option>
          </Select>
        </AnswerSection>
        <Label>Who is your electricity provider?</Label>
        <FormInput
          type="text"
          name="provider"
          placeholder={placeholders.provider}
          onFocus={() => handleFocus("provider")}
          onBlur={handleBlur}
          required
        />
        <Label>What is your average monthly electricity cost?</Label>
        <FormInput
          type="text"
          name="dollar"
          placeholder={placeholders.dollar}
          onFocus={() => handleFocus("dollar")}
          onBlur={handleBlur}
          required
        />
        <Label>What county do you reside in?</Label>
        <FormInput
          type="text"
          name="text"
          placeholder={placeholders.text}
          onFocus={() => handleFocus("text")}
          onBlur={handleBlur}
          required
        />
      </>

      <>
        <Label>Please provide a good contact email</Label>
        <FormInput
          type="email"
          name="email"
          placeholder={placeholders.email}
          onFocus={() => handleFocus("email")}
          onBlur={handleBlur}
          required
        />

        <ButtonContainer>
          <SubmitButton type="submit">Submit</SubmitButton>
        </ButtonContainer>
      </>
    </FormContainer>
  );
};

export default Solar;
