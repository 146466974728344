import React, { useState } from "react";
import {
  OuterFAQContainer,
  HeaderContainer,
  QuestionContainer,
  Question,
  QuestionText,
  Answer,
  FAQContainer,
  ChevronDownIcon,
  ChevronUpIcon,
} from "./style";

const FaqItem = ({ question, answer, isOpen, onClick }) => (
  <>
    <Question onClick={onClick}>
      <QuestionText>{question}</QuestionText>
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </Question>
    <Answer isVisible={isOpen}>{answer}</Answer>
  </>
);

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How long do solar panels really last?",
      answer:
        "Most manufacturers warranty their solar panels to produce efficiently for 25 years!",
    },
    {
      question: "What happens if I decide to move?",
      answer:
        "Your manufacturer warranty is transferable to the new homeowner. If your system is not paid off, you can either add what is owed to the sale of your home, or have the new homeowner take over your solar payments!",
    },
    {
      question: "How much can I expect to save when I go solar?",
      answer:
        "Every case is particular to the individual home. Our experts can show you your potential cost savings in a free consultation!",
    },
    {
      question: "Does my state offer incentives to go solar?",
      answer:
        "Although some states have additional incentives for going solar, there is a 30% federal tax incentive (ITC) for making the move to green energy. Regardless of what state you live in, solar can be very beneficial to you.",
    },
    {
      question: "How many solar panels will my home need?",
      answer:
        "Our experts will take a look at your home's energy consumption and explain how many panels you will need to turn your home into an energy producing asset with a free consultation.",
    },
    {
      question: "Is my home good for solar?",
      answer:
        "If you are spending more than $125/month on electricity, and you have clear access to sunlight, then absolutely!",
    },
  ];

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <OuterFAQContainer id="about">
      <HeaderContainer>FAQs</HeaderContainer>
      <QuestionContainer>
        <FAQContainer>
          {faqs.map((faq, index) => (
            <FaqItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === index}
              onClick={() => handleClick(index)}
            />
          ))}
        </FAQContainer>
      </QuestionContainer>
    </OuterFAQContainer>
  );
};

export default FAQSection;
