import React, { useState } from "react";
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroH2,
  HeroP,
  HeroT,
  TitleContainer,
  Img,
  ImgContainer,
  ContentContainer,
  InnerSection,
  CircleContainer,
  CircleText,
  Divide,
  TextContainer,
  Mask,
  PopupContainer,
  PopupImage,
  PopupText,
  CallNumber,
  HeroObject,
  HeroContainerObject,
  ObjectImg,
  ObjectImg2,
  ObjectImg3,
  ObjectImg4,
  WordOne,
  WordTwo,
} from "./style";
import Solar from "./images/solar.jpg";

const HeroSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeroContainer id="/">
      <InnerSection>
        <TitleContainer>
          <HeroH1>
            <WordOne>Advanced</WordOne> <WordTwo>Equipment</WordTwo>
          </HeroH1>
          <HeroH2>Intelligent Design</HeroH2>
        </TitleContainer>
        {/* <CircleContainer onClick={() => setIsOpen(true)}>
          <TextContainer>
            <CircleText>Service</CircleText>
            <CircleText>Member?</CircleText>
          </TextContainer>
          <IconButton>
            <HiOutlineSearchCircle />
          </IconButton>
          <Icon>
            <MdTrendingFlat />
          </Icon>
        </CircleContainer> */}
        <ContentContainer>
          <HeroContent>
            <HeroContainerObject>
              <HeroObject>
                Stay ahead of your energy demands with state-of-the-art solar
                <br />
                and home backup solutions
              </HeroObject>
            </HeroContainerObject>
            {/* <HeroContainerObject>
              {/* <ObjectImg2 src={Bill} alt="Superior Quality" /> 
              <HeroObject>
                175000+ <br />
                kWh Energy Saved
              </HeroObject>
            </HeroContainerObject> */}
            {/* <HeroContainerObject>
              {/* <ObjectImg3 src={Money} alt="Superior Quality" /> 
              <HeroObject>
                100+ <br />5 Star Customer Reviews
              </HeroObject>
            </HeroContainerObject> */}
            {/* <HeroContainerObject>
              {/* <ObjectImg4 src={Star} alt="Superior Quality" /> 
              <HeroObject>
                300+ <br />
                EV Chargers Installed
              </HeroObject>
            </HeroContainerObject> */}

            {/*<HeroP>
              We have leading experts with multiple years of experience
            </HeroP>
            <Divide></Divide>
            <HeroT>We are driven to provide the best customer service</HeroT>*/}
          </HeroContent>
          <CallNumber>
            <div>Call Us:</div>
            <div>813-467-6859</div>
          </CallNumber>
          {/*<BackgroundBubble></BackgroundBubble>*/}
        </ContentContainer>

        <ImgContainer>
          {/* <Img src={Solar} alt="Superior Quality" /> */}
        </ImgContainer>
      </InnerSection>
      {isOpen && (
        <Mask onClick={() => setIsOpen(false)}>
          <PopupContainer>
            {/* <PopupImage src={Veteran} alt="Massive Savings"></PopupImage> */}
            <PopupText>All prior or current military</PopupText>
            <PopupText>Save $100 per Kilowatt</PopupText>
          </PopupContainer>
        </Mask>
      )}
    </HeroContainer>
  );
};

export default HeroSection;
